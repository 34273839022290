import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import firebase from "firebase/app";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { PostDataV3, PostsOrderBy, PostV3 } from "../db/schema";
import PostView from "./PostView";

type Props = {
  orderBy: PostsOrderBy;
  moderatorKey: string | null;
};

const LIMIT = 20;

const getQuery = (orderBy: PostsOrderBy, isModerator: boolean) => {
  const db = firebase.firestore();

  let query: firebase.firestore.Query = db.collection("posts_v3");
  if (!isModerator) {
    query = query.where("isHidden", "==", false);
  }
  query = query.orderBy(orderBy, "desc");
  query = query.limit(LIMIT);

  return query;
};

const usePosts = (orderBy: PostsOrderBy, isModerator: boolean) => {
  const [posts, setPosts] = useState<PostV3[] | null>(null);
  const lastDocRef = useRef<firebase.firestore.QueryDocumentSnapshot | null>(
    null
  );

  useEffect(() => {
    getQuery(orderBy, isModerator)
      .get()
      .then(snap => {
        const posts: PostV3[] = snap.docs.map(doc => {
          const data = doc.data() as PostDataV3;
          return { id: doc.id, ...data };
        });

        const lastDoc =
          snap.docs.length > 0 ? snap.docs[snap.docs.length - 1] : null;
        lastDocRef.current = lastDoc;

        setPosts(posts);
      });
  }, [orderBy, isModerator]);

  const loadMore = useCallback(() => {
    if (!lastDocRef.current) return;

    getQuery(orderBy, isModerator)
      .startAfter(lastDocRef.current)
      .get()
      .then(snap => {
        const posts: PostV3[] = snap.docs.map(doc => {
          const data = doc.data() as PostDataV3;
          return { id: doc.id, ...data };
        });

        const lastDoc =
          snap.docs.length > 0 ? snap.docs[snap.docs.length - 1] : null;
        lastDocRef.current = lastDoc;

        setPosts(prev => [...(prev || []), ...posts]);
      });
  }, [orderBy, isModerator, setPosts]);

  return {
    posts,
    setPosts,
    loadMore,
    hasMore: lastDocRef.current != null
  };
};

const useStyles = makeStyles(theme => ({
  loadMore: {
    display: "flex",
    justifyContent: "center",
    flexGrow: 1,
    marginTop: theme.spacing(2)
  }
}));

function Posts({ orderBy, moderatorKey }: Props) {
  const { posts, setPosts, loadMore, hasMore } = usePosts(
    orderBy,
    !!moderatorKey
  );
  const classes = useStyles();

  return (
    <>
      {(posts || []).map(post => (
        <PostView
          key={post.id}
          post={post}
          moderatorKey={moderatorKey}
          onDelete={postId => {
            if (!posts) return;
            if (!posts.some(post => post.id === postId)) return;

            setPosts(posts.filter(post => post.id === postId));
          }}
        />
      ))}

      {hasMore && (
        <div className={classes.loadMore}>
          <Button onClick={loadMore}>Load More</Button>
        </div>
      )}
    </>
  );
}

export default Posts;

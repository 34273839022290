import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import React, { useCallback, useMemo, useState } from "react";
import { PostsOrderBy } from "../db/schema";
import Posts from "./Posts";
import Blurb from "./Blurb";

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2)
  }
}));

function Home() {
  const [orderBy, setOrderBy] = useState<PostsOrderBy>("createdAt");
  const moderatorKey = useMemo(
    () => new URLSearchParams(window.location.search).get("moderatorKey"),
    []
  );
  const classes = useStyles();

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Blurb />
        </Grid>

        <Grid item xs={12}>
          <Button
            color={orderBy === "createdAt" ? "primary" : "default"}
            onClick={useCallback(() => {
              setOrderBy("createdAt");
            }, [])}
          >
            Order by time
          </Button>

          <Button
            color={orderBy === "voteTotal" ? "primary" : "default"}
            onClick={useCallback(() => {
              setOrderBy("voteTotal");
            }, [])}
          >
            Order by votes
          </Button>
        </Grid>

        <Posts orderBy={orderBy} moderatorKey={moderatorKey} />
      </Grid>
    </Container>
  );
}

export default Home;

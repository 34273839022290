import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import firebase from "firebase/app";
import React, { useCallback, useState } from "react";
import { RouteComponentProps } from "react-router";
import { PostDataV3 } from "../db/schema";
import Blurb from "./Blurb";

type ChangeEvent = React.ChangeEvent<
  HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
>;

const CHAR_LIMIT = 500;

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2)
  },
  header: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    color: theme.palette.text.primary
  },
  postBody: {
    resize: "vertical"
  }
}));

type Props = {} & RouteComponentProps;

function CreatePost({ history }: Props) {
  const [body, setBody] = useState("");
  const [author, setAuthor] = useState("");
  const classes = useStyles();

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Blurb />

      <Typography variant="h5" component="h1" className={classes.header}>
        Add a post
      </Typography>

      <TextField
        required
        inputProps={{ className: classes.postBody }}
        label="Post"
        multiline
        rows="10"
        margin="normal"
        variant="outlined"
        value={body}
        fullWidth
        onChange={useCallback((ev: ChangeEvent) => {
          setBody(ev.target.value);
        }, [])}
        error={body.length > CHAR_LIMIT}
        helperText={`${CHAR_LIMIT} characters or less`}
      />

      <TextField
        required
        label="Your name"
        inputProps={{
          autoComplete: "name"
        }}
        margin="normal"
        variant="outlined"
        value={author}
        fullWidth
        onChange={useCallback((ev: ChangeEvent) => {
          setAuthor(ev.target.value);
        }, [])}
      />

      <Box mt={2}>
        <Button
          size="large"
          variant="contained"
          color="primary"
          disabled={body === "" || author === "" || body.length > CHAR_LIMIT}
          onClick={useCallback(() => {
            const db = firebase.firestore();
            const postData: PostDataV3 = {
              author,
              body,
              createdAt: firebase.firestore.Timestamp.now(),
              isHidden: false,
              voteCount: 0,
              voteTotal: 0,
              votes: []
            };
            db.collection("posts_v3")
              .add(postData)
              .then(() => {
                history.push("/" + history.location.search);
              });
          }, [author, body, history])}
        >
          Create Post
        </Button>
      </Box>
    </Container>
  );
}

export default CreatePost;

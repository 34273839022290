import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AddBoxOutlined from "@material-ui/icons/AddBoxOutlined";
import React from "react";
import { Link } from "react-router-dom";
import AdapterLink from "./AdapterLink";
import useRouter from "./useRouter";

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  titleLink: {
    color: "inherit",
    textDecoration: "none"
  }
}));

function TopBar() {
  const classes = useStyles();
  const { history } = useRouter();

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" color="inherit" className={classes.title}>
          <Link
            to={"/" + history.location.search}
            className={classes.titleLink}
          >
            Deep Phenomena
          </Link>
        </Typography>

        <Button
          color="inherit"
          component={AdapterLink}
          to={"/create" + history.location.search}
        >
          <AddBoxOutlined className={classes.leftIcon} />
          Add a Post
        </Button>
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;

import React from "react";
import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  container: {
    ...theme.typography.subtitle1,
    lineHeight: 1.5,
    textAlign: "center",
    backgroundColor: grey[200],
    padding: theme.spacing(1)
  }
}));

function Blurb() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      Have you seen something interesting or curious or mysterious while
      training a deep neural network?
      <br />
      Share these interesting and unusual deep learning phenomena here!
      <br />
      Come to the workshop and give a talk about your phenomenon!
    </div>
  );
}

export default Blurb;

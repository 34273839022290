import IconButton from "@material-ui/core/IconButton";
import firebase from "firebase/app";
import React, { useCallback, useContext } from "react";
import { PostDataV3, VoteV3 } from "../db/schema";
import UserContext from "./UserContext";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";

type Props = {
  postId: string;
  vote: VoteV3 | null;
  setVote: (vote: VoteV3 | null) => void;
  value: VoteV3["value"];
  loading: boolean;
  setLoading: (loading: boolean) => void;
  className?: string;
  setTally: (tally: { voteCount: number; voteTotal: number }) => void;
};

function VoteButton({
  postId,
  vote,
  setVote,
  value,
  loading,
  setLoading,
  className,
  setTally
}: Props) {
  const user = useContext(UserContext);
  const userId = user && user.uid;

  return (
    <IconButton
      className={className}
      aria-label={value > 0 ? "Upvote" : "Downvote"}
      disabled={loading}
      color={vote && vote.value === value ? "primary" : "default"}
      onClick={useCallback(() => {
        // TODO: handle no user id case
        if (!userId) return;

        const finalValue = vote && vote.value === value ? 0 : value;
        setLoading(true);
        saveVote(postId, userId, finalValue).then(updates => {
          setLoading(false);
          setVote(updates.vote);
          setTally({
            voteCount: updates.voteCount,
            voteTotal: updates.voteTotal
          });
        });
      }, [postId, setLoading, setTally, setVote, userId, value, vote])}
    >
      {value > 0 ? <ThumbUpIcon /> : <ThumbDownIcon />}
    </IconButton>
  );
}

export default VoteButton;

function saveVote(
  postId: string,
  /** Voter's user id. */
  uid: string,
  value: VoteV3["value"]
) {
  const db = firebase.firestore();
  const ref = db.doc(`posts_v3/${postId}`);

  return db.runTransaction(async transaction => {
    const doc = await transaction.get(ref);
    if (!doc.exists) {
      throw new Error(`Post ${postId} does not exist.`);
    }

    const data = doc.data() as PostDataV3;
    if (!data) {
      throw new Error(`No post data for ${postId}.`);
    }

    const voteIndex = data.votes.findIndex(vote => vote.uid === uid);
    const vote = voteIndex !== -1 ? data.votes[voteIndex] : null;

    const nextVoteCount = vote ? data.voteCount : data.voteCount + 1;
    const nextVoteTotal = vote
      ? data.voteTotal - vote.value + value
      : data.voteTotal + value;

    const nextVote: VoteV3 = { uid, value };

    const nextVotes = [...data.votes];
    if (voteIndex !== -1) {
      nextVotes.splice(voteIndex, 1, nextVote);
    } else {
      nextVotes.push(nextVote);
    }

    const updates: Partial<PostDataV3> = {
      voteCount: nextVoteCount,
      voteTotal: nextVoteTotal,
      votes: nextVotes
    };

    transaction.update(ref, updates);

    return {
      voteCount: nextVoteCount,
      voteTotal: nextVoteTotal,
      vote: nextVote
    };
  });
}

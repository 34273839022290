import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useContext, useState } from "react";
import { PostV3 } from "../db/schema";
import ModeratorControls from "./ModeratorControls";
import UserContext from "./UserContext";
import VoteButton from "./VoteButton";

type Props = {
  post: PostV3;
  moderatorKey: string | null;
  onDelete: (postId: string) => void;
};

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    alignItems: "center"
  },
  votesContainer: {
    flex: "0 0 auto",
    padding: theme.spacing(2),
    paddingRight: 0
  },
  postContainer: {
    flex: "1 1 auto",
    padding: theme.spacing(2)
  },
  postBody: {
    whiteSpace: "pre-wrap"
  },
  postDetails: {
    color: theme.palette.text.secondary
  },
  button: {
    flex: "1 1 auto"
  },
  thumbs: {
    display: "flex",
    flexWrap: "wrap"
  }
}));

const useVote = (post: PostV3, userId: string | null) => {
  const initialVote = post.votes.find(vote => vote.uid === userId) || null;
  const [vote, setVote] = useState(initialVote);

  return { vote, setVote };
};

type Updates = {
  voteCount: number;
  voteTotal: number;
};

function PostView({ post, moderatorKey, onDelete }: Props) {
  const classes = useStyles();
  const user = useContext(UserContext);
  const userId = user && user.uid;
  const { vote, setVote } = useVote(post, userId);
  const [loading, setLoading] = useState(false);
  const [tally, setTally] = useState<Updates>({
    voteCount: post.voteCount,
    voteTotal: post.voteTotal
  });

  return (
    <Grid item xs={12}>
      <Paper>
        {moderatorKey != null && (
          <ModeratorControls
            post={post}
            moderatorKey={moderatorKey}
            onDelete={onDelete}
          />
        )}

        <div className={classes.container}>
          <div className={classes.votesContainer}>
            <div className={classes.thumbs}>
              <VoteButton
                className={classes.button}
                postId={post.id}
                vote={vote}
                setVote={setVote}
                value={1}
                loading={loading}
                setLoading={setLoading}
                setTally={setTally}
              />
              <VoteButton
                className={classes.button}
                postId={post.id}
                vote={vote}
                setVote={setVote}
                value={-1}
                loading={loading}
                setLoading={setLoading}
                setTally={setTally}
              />
            </div>

            <Typography
              align="center"
              color="textPrimary"
              display="block"
              variant="subtitle1"
            >
              {tally.voteTotal}
            </Typography>
            <Typography
              align="center"
              variant="caption"
              display="block"
              color="textSecondary"
            >
              {`(${tally.voteCount} ${
                tally.voteCount === 1 ? "vote" : "votes"
              })`}
            </Typography>
          </div>

          <div className={classes.postContainer}>
            <Typography
              className={classes.postBody}
              display="block"
              color="textPrimary"
              gutterBottom
            >
              {post.body}
            </Typography>

            <Typography
              className={classes.postDetails}
              display="block"
              color="textSecondary"
            >
              Posted at {post.createdAt.toDate().toLocaleString()} by{" "}
              {post.author}
            </Typography>
          </div>
        </div>
      </Paper>
    </Grid>
  );
}

export default PostView;

import React, { useState, useCallback } from "react";
import firebase from "firebase/app";
import { PostV3 } from "../db/schema";

type Props = {
  post: PostV3;
  moderatorKey: string;
  onDelete: (postId: string) => void;
};

function ModeratorControls({ post, moderatorKey, onDelete }: Props) {
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);
  const [isHidden, setIsHidden] = useState(post.isHidden);
  const postId = post.id;

  const doAction = useCallback(
    (action: "show" | "hide" | "delete") => {
      setLoading(true);
      const moderatePost = firebase.functions().httpsCallable("moderatePostV2");
      moderatePost({ postId, moderatorKey, action })
        .then(() => {
          setLoading(false);
          setError(null);
          if (action === "show" || action === "hide") {
            setIsHidden(action === "hide");
          } else if (action === "delete") {
            onDelete(postId);
          }
        })
        .catch(error => {
          setLoading(false);
          setError(error);
        });
    },
    [postId, moderatorKey, onDelete]
  );

  return (
    <div>
      {error && <div>Error Occurred</div>}
      {loading && <div>Loading...</div>}

      <button
        disabled={loading}
        onClick={useCallback(() => {
          if (window.confirm("Are you sure?")) {
            doAction("delete");
          }
        }, [doAction])}
      >
        Delete
      </button>

      <button
        disabled={loading || isHidden}
        onClick={useCallback(() => {
          doAction("hide");
        }, [doAction])}
      >
        Hide
      </button>

      <button
        disabled={loading || !isHidden}
        onClick={useCallback(() => {
          doAction("show");
        }, [doAction])}
      >
        Show
      </button>
    </div>
  );
}

export default ModeratorControls;

import React from "react";
import ReactDOM from "react-dom";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/functions";

import "./index.css";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";

const firebaseConfig = {
  apiKey: "AIzaSyAKDe5jIIgow69tsb-CLKaGSFtpEGN1or0",
  authDomain: "deep-phenomena.firebaseapp.com",
  databaseURL: "https://deep-phenomena.firebaseio.com",
  projectId: "deep-phenomena",
  storageBucket: "deep-phenomena.appspot.com",
  messagingSenderId: "748353640948",
  appId: "1:748353640948:web:973e438eac74cc7f"
};
firebase.initializeApp(firebaseConfig);

if (typeof window !== "undefined" && window.location.hostname === "localhost") {
  firebase.functions().useFunctionsEmulator(window.location.origin);
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import CssBaseline from "@material-ui/core/CssBaseline";
import firebase from "firebase/app";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import CreatePost from "./CreatePost";
import Home from "./Home";
import TopBar from "./TopBar";
import UserContext from "./UserContext";

const useAnonymousUser = () => {
  const [user, setUser] = useState<firebase.User | null>(null);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(result => {
      setUser(result);
    });

    firebase
      .auth()
      .signInAnonymously()
      .catch(error => {
        console.log(error);
      });

    return unsubscribe;
  }, []);

  return user;
};

function App() {
  const user = useAnonymousUser();

  return (
    <BrowserRouter>
      <UserContext.Provider value={user}>
        <CssBaseline />

        <TopBar />

        <Route exact path="/" component={Home} />
        <Route exact path="/create" component={CreatePost} />
      </UserContext.Provider>
    </BrowserRouter>
  );
}

export default App;
